/*!
 * Moonkake v5.0.0
 *
 * https://github.com/detectiveshelby/moonkake
 */

$(function () {

  /* VAR
  -------------------------------------------------- */

  var $d = $(document);
  var $w = $(window);
  var $h = $('html');
  var $b = $('body');

  /* UTILS
  -------------------------------------------------- */

  var $mk = {
    scrollTo: function ($object, offset, callback) {
      $('html, body').stop().animate({
        scrollTop: $object.offset().top - ((typeof (offset) == 'number') ? offset : 0)
      }, 500, function () {
        if ($(this)[0].nodeName == 'HTML') {
          if (typeof (callback) == 'function') {
            callback();
          }
        }
      });
    },
  };

  /* SCROLL
  -------------------------------------------------- */

  $('.js-scroll').on('click', function () {
    var href = $(this).attr('href');

    $mk.scrollTo($(href), 0);

    return false;
  });

  /* POPUP
  -------------------------------------------------- */

  $('.js-popup').magnificPopup({
    type: 'inline',
    midClick: true,
    closeBtnInside: true,
    removalDelay: 300,
    mainClass: 'mfp-fade',
    fixedContentPos: false,
    callbacks: {
      beforeOpen: function() {
        $d.trigger('mfpPopupBeforeOpen', {
          instance: this
        });
      },
      open: function() {
        $d.trigger('mfpPopupOpen', {
          instance: this
        });
      },
      beforeClose: function() {
        $d.trigger('mfpPopupBeforeClose', {
          instance: this
        });
      },
      close: function() {
        $d.trigger('mfpPopupClose', {
          instance: this
        });
      },
      afterClose: function() {
        $d.trigger('mfpPopupAfterClose', {
          instance: this
        });
      }
    }
  });

  $('.js-image').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    removalDelay: 300,
    mainClass: 'mfp-fade mfp-img-mobile',
    image: {
      verticalFit: true
    },
    callbacks: {
      beforeOpen: function() {
        $d.trigger('mfpImageBeforeOpen', {
          instance: this
        });
      },
      open: function() {
        $d.trigger('mfpImageOpen', {
          instance: this
        });
      },
      beforeClose: function() {
        $d.trigger('mfpImageBeforeClose', {
          instance: this
        });
      },
      close: function() {
        $d.trigger('mfpImageClose', {
          instance: this
        });
      },
      afterClose: function() {
        $d.trigger('mfpImageAfterClose', {
          instance: this
        });
      }
    }
  });

  $('.js-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Загрузка изображения #%curr%...',
    mainClass: 'mfp-fade mfp-img-mobile',
    removalDelay: 300,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1],
      tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
    },
    image: {
      tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.'
    },
    callbacks: {
      beforeOpen: function() {
        $d.trigger('mfpGalleryBeforeOpen', {
          instance: this
        });
      },
      open: function() {
        $d.trigger('mfpGalleryOpen', {
          instance: this
        });
      },
      beforeClose: function() {
        $d.trigger('mfpGalleryBeforeClose', {
          instance: this
        });
      },
      close: function() {
        $d.trigger('mfpGalleryClose', {
          instance: this
        });
      },
      afterClose: function() {
        $d.trigger('mfpGalleryAfterClose', {
          instance: this
        });
      }
    }
  });

  /* CAROUSEL
  -------------------------------------------------- */

  $('.carousel-license').owlCarousel({
    items: 1,
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000
  });

  /* NAVIGATION
  -------------------------------------------------- */

  var $toggler = {
    options: {
      toggler: 'data-toggler',
      target: 'data-target',
      active: '-active'
    },

    init: function ($options) {
      var $this = this;

      if (typeof $options == 'object') {
        $this.options = $options;
      }

      $('[' + $this.options.toggler + ']').on('click', function () {
        var _ = $(this);
        var target = _.attr($this.options.target);

        $('[' + $this.options.target + ']').each(function () {
          var _ = $(this);
          var currentTarget = _.attr($this.options.target);

          if (currentTarget !== target) {
            _.removeClass($this.options.active);
            $('#' + currentTarget).removeClass($this.options.active);
          }
        });

        _.toggleClass($this.options.active);

        $('#' + target).toggleClass($this.options.active);

        return false;
      });

      $d.on('click', function () {
        $('[' + $this.options.toggler + ']').removeClass($this.options.active);

        $('[' + $this.options.toggler + ']').each(function () {
          var _ = $(this);
          var target = _.attr($this.options.target);

          _.removeClass($this.options.active);
          $('#' + target).removeClass($this.options.active);
        });
      });

      $('[' + $this.options.toggler + ']').on('click', function (event) {
        event.stopPropagation();
      });

      $('[' + $this.options.toggler + ']').each(function() {
        var _ = $(this);
        var target = _.attr($this.options.target);

        $('#' + target).each(function() {
          $(this).on('click', function (event) {
            event.stopPropagation();
          });
        });
      });

    }
  }

  $toggler.init();

  /* INPUTMASK
  -------------------------------------------------- */

  $('input[type="tel"]').inputmask({
    mask: '+7 (999) 999-99-99'
  });

});
